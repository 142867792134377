import * as React from "react";
import Link from "gatsby-link";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Para, PrimaryButton } from "../components/basic";

const pageSchemaContent = {
  name: "404: Not found",
  breadcrumb: {
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://imchetanyadav.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "404",
        item: "https://imchetanyadav.com/404",
      },
    ],
  },
};

export default () => (
  <Layout>
    <SEO
      pageTitle="404: Not found"
      pageDescription="You just hit a route that doesn't exist... the sadness."
      pageSchemaContent={pageSchemaContent}
      pageKeywords={["404"]}
    />
    <div
      style={{
        paddingTop: "100px",
        textAlign: "center",
      }}
    >
      <Para>You just hit a route that doesn't exist... the sadness.</Para>

      <Link to="/">
        <PrimaryButton>Go Back</PrimaryButton>
      </Link>
    </div>
  </Layout>
);
